
$loader-color:              #0097c1 !default;
$loader-size:                  56px !default;
$loader-height:                20px !default;
$loader-border-size:            8px !default;
$loader-gap:                   12px !default;
$loader-animation-duration:      1s !default;

@mixin iconIoading(
  $size: $loader-size,
  $color: $loader-color,
  $duration: $loader-animation-duration,
  $gap: $loader-gap,
  $align: null
) {
  $unique-name: unique-id();
  width: $size;
  height: $size;
  border-radius: 50%;
  box-shadow: 0 $size * 2 0 $color;
  position: relative;
  animation: #{'iconloading-'}#{$unique-name} $duration ease-in-out alternate infinite;
  animation-delay: ($duration / 5) * 2;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: $size;
    height: $size;
    border-radius: 50%;
    box-shadow: 0 $size * 2 0 $color;
    animation: #{'iconloading-'}#{$unique-name} $duration ease-in-out alternate infinite;
  }

  &::before {
    left: -($size + $gap);
    animation-delay: ($duration / 5) * 3;

  }

  &::after {
    right: -($size + $gap);
    animation-delay: ($duration / 5) * 1;
  }

  @keyframes #{'iconloading-'}#{$unique-name} {
    0% {
      box-shadow: 0 $size * 2 0 $color;
    }

    100% {
      box-shadow: 0 $size 0 $color;
    }
  }
}

#APP-LOADING .icon{
  @include iconIoading($size: 20px, $gap: 10px, $duration: 0.8s);
}