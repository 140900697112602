@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;500;700&display=swap');

body, #root {
  font-family: 'Prompt', sans-serif;
}

.wrap-page {
  margin-top: 120px;
  min-height: calc(100vh - 170px);
  position: relative;
  @media (max-width: 540px) {
    min-height: auto;
    margin-top: 0px;
  }
}