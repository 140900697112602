$rt-namespace: 'Toastify';
$rt-toast-width: 320px !default;
$rt-toast-background: #fff !default;
$rt-toast-min-height: 50px !default;
$rt-toast-max-height: 800px !default;

$rt-color-default: #fff !default;
$rt-color-dark: #121212 !default;
$rt-color-info: #3498db !default;
$rt-color-success: rgba(38, 166, 154, 0.9) !default;
$rt-color-warning: #f1c40f !default;
$rt-color-error: rgba(224, 32, 32, 0.9) !default;

$rt-text-color-default: #aaa !default;
$rt-text-color-dark: #fff !default;

$rt-color-progress-default: linear-gradient(
  to right,
  #4cd964,
  #5ac8fa,
  #007aff,
  #34aadc,
  #5856d6,
  #ff2d55
) !default;
$rt-color-progress-dark: #bb86fc !default;
$rt-mobile: 'only screen and (max-width : 480px)' !default;
$rt-font-family: 'Prompt', sans-serif !default;
$rt-z-index: 9999 !default;
