:root {
  --color-primary: #0097C1;
  --color-primary-hover: #0281a4;
  --color-black: #000000;
  --color-secondary: #4A4A4A;
  --color-dark: #333333;
  --color-graphite: #8A8A8A;
  --color-dark-grey: #E5E5E5;
  --color-light-grey: #F5F5F5;
  --color-white: #FFFFFF;
  --color-danger: #E02020;
  --color-danger-hover: #b20f0f;
}