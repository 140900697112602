.#{$rt-namespace}__toast-container {
  z-index: $rt-z-index;
  -webkit-transform: translate3d(0, 0, #{$rt-z-index}px);
  position: fixed;
  padding: 4px;
  max-width: $rt-toast-width;
  box-sizing: border-box;
  color: #fff;
  &--top-left {
    top: 1em;
    left: 1em;
  }
  &--top-center {
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }
  &--top-right {
    top: 1em;
    right: 1em;
  }
  &--bottom-left {
    bottom: 1em;
    left: 1em;
  }
  &--bottom-center {
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  }
  &--bottom-right {
    bottom: 1em;
    right: 1em;
  }
}

@media #{$rt-mobile} {
  .#{$rt-namespace}__toast-container {
    min-width: 300px;
    padding: 0;
    left: 0;
    top: 30px;
    margin: 0;
    left: 50%;

    transform: translateX(-50%);
  }
}
